<template>
  <section id="check">
    <div class="join_info">
      <div class="reserve_info">
        {{ date }}
        {{ time }}
        {{ course }}
        <p>
          <strong>{{ ccName }}</strong>
        </p>
      </div>
    </div>
    <div class="locker_wrap">
      <a class="btn_point btn_locker" @click="onClickLockerOk(true)">
        {{ $t("main.useLocker") }}
      </a>
      <a class="btn_basic btn_locker" @click="onClickLockerOk(false)">
        {{ $t("main.unusedLocker") }}
      </a>
    </div>
    <div class="locker_service">
      <p class="stitle">{{ $t("main.lockerKeyGuide") }}</p>
      <ul class="ul_locker_service">
        <li v-for="n in 4" :key="n">
          <span>{{ n }}</span>
          {{ $t(`lockerGuideMessage._${n}`) }}
        </li>
        <li>
          {{ $t(`lockerGuideMessage.special`) }}
        </li>
      </ul>
    </div>
    <locker-ok-popup
      v-if="isLockerOkPopupOpened"
      :lockerNo="signedInCmpnMemberLockerNo"
      :sexName="signedInCmpnSexName"
      @submit="onLockerOkPopupSubmit"
    />
  </section>
</template>

<script>
import LockerOkPopup from "@/components/reservations/popup/LockerOkPopup";
import geolocationMixin from "../../mixins/geolocationMixin";
import ProjectService from "@/service/ProjectService";
import { getSexName } from "@/mixins/commonCodesMixin";
import {
  getCurrentSessionGrant,
  getCurrentSessionSno,
} from "@/utils/session/SessionUtil";
import { mapGetters } from "vuex";

export default {
  name: "CheckIn",
  components: { LockerOkPopup },
  mixins: [geolocationMixin],
  data() {
    return {
      reservationId: null,
      date: null,
      time: null,
      course: null,
      count: null,
      query: null,
      reservationCmpnList: [],
      checkInKey: null,
      isBooker: getCurrentSessionGrant() === "S_FRONT_BOOKER",
      isUseLocker: false,
      isLockerOkPopupOpened: false,
    };
  },
  created() {
    this.reservationId = Number(this.$route.params.reservationId) || 0;
    this.date = this.$route.query.date;
    this.time = this.$route.query.time;
    this.course = this.$route.query.course;
    this.count = this.$route.query.count;
    this.query = `?date=${this.date}&time=${this.time}&course=${this.course}&count=${this.count}`;
    this.checkInKey = this.isBooker ? 1 : getCurrentSessionSno();
  },
  computed: {
    ...mapGetters(["ccName"]),
    signedInCmpnMemberInfo() {
      return this.reservationCmpnList?.find(
        (member) => member.sno === this.checkInKey
      );
    },
    signedInCmpnSexName() {
      return getSexName(this.signedInCmpnMemberInfo?.sexCode);
    },
    signedInCmpnMemberLockerNo() {
      return (
        this.signedInCmpnMemberInfo?.lockerSector
          ? this.signedInCmpnMemberInfo?.lockerSector.concat(" ")
          : ""
      ).concat(this.signedInCmpnMemberInfo?.lockerNo);
    },
  },
  methods: {
    async onClickLockerOk(isUseLocker) {
      if (this.isLockerOkPopupOpened) return;
      this.isUseLocker = isUseLocker;
      await this.beforeCheckIn();
    },
    async onLockerOkPopupSubmit() {
      this.isLockerOkPopupOpened = false;
      await this.goCheckInStatusPage();
    },
    async beforeCheckIn() {
      await this.confirm({
        content: this.$t(
          "lockerKeyConfirmMessage." +
            (this.isUseLocker ? "isUseLocker" : "isNotUseLocker")
        ),
        cancelLabel: this.$t("common.no"),
        submitLabel: this.$t("common.yes"),
        cancelCallback: async () => await this.goCheckInPage(),
        submitCallback: async () => await this.checkIn(),
      });
    },
    async checkIn() {
      const geolocation = await this.getGeolocation();
      // check-in
      const {
        data: { tgResveConfirm },
      } = await ProjectService.postUserCheckIn(
        this.isUseLocker,
        this.reservationId,
        geolocation.longitude,
        geolocation.latitude
      );
      this.reservationCmpnList = tgResveConfirm.tgResveCmpns;
      await this.$nextTick();
      this.isLockerOkPopupOpened = true;
    },
    async goCheckInPage() {
      await this.$router.push(
        `/reservations/${this.reservationId}${this.query}`
      );
    },
    async goCheckInStatusPage() {
      await this.$router.push(
        `/reservations/${this.reservationId}/check-in-status`
      );
    },
  },
};
</script>
